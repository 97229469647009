import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Menu from "../../menu/Menu";
import logo from '../../../images/logo.svg';
import { RootState } from '../../store';

const Sidebar = () => {
    const isnavCollpase = useSelector((state: RootState) => state.navCollapse.isCollpase);
    return (
        <div className={isnavCollpase ? "bk-sidebar active" : "bk-sidebar"}>
            <div className="logo-bk">
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <Menu />
        </div>
    )
}

export default Sidebar;