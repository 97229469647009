import React, { useCallback, useState } from "react";
import axios from "axios";


export type axiosPostReturnType = {
    data?: any,
    isLoaing: boolean,
    error: string,
    postRequest: (url: string, body: {} | string) => void;
}

const useAxiosPost = () => {
    const [data, setData] = useState();
    const [isLoaing, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const tocken = localStorage.getItem('jwt');

    const postRequest = useCallback((url: string, body: {} | string) => {
        setIsLoading(true);
        axios.post(baseUrl + url, body, {
            headers: {
                'Oakey': apiKey,
                'Authorization': 'Bearer ' + tocken,
            },
        })
            .then(response => {
                setIsLoading(false);
                setData(response.data)
            })
            .catch(error => {
                setError(error.message);
            })
    }, [])

    return { data, isLoaing, error, postRequest }
}

export default useAxiosPost;