import { createSlice } from "@reduxjs/toolkit";
import { type } from "os";

type navCollapseType = {
    isCollpase: boolean,
}

const navCollapse: navCollapseType = {
    isCollpase: false,
}

const navbarCollapseSlice = createSlice({
    name: "navbarCollapse",
    initialState: navCollapse,
    reducers: {
        toggleNavbarCollapse: (state) => {
            state.isCollpase = !state.isCollpase;
        },
    }
})

export const { toggleNavbarCollapse } = navbarCollapseSlice.actions;

export default navbarCollapseSlice;