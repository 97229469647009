import { useDispatch } from "react-redux";
import { authSliceActions } from "../../store/authSlice";
import { Link } from "react-router-dom";
const UserDropdown = () => {
    const dispatch = useDispatch();
    const logutHandler = () => {
        dispatch(authSliceActions.logoutHandler());
    };

    return (
        <div className="user-dropdown">
            <Link to="/editProfile" className="dropdown-item">Edit Profile</Link>
            <button className="dropdown-item" onClick={logutHandler}>Logout</button>
        </div>
    );
};

export default UserDropdown;