import { createSlice } from "@reduxjs/toolkit";
import { type } from "os";

type isLoggedInType = {
    isLoggedIn: boolean,
}

const initialState: isLoggedInType = {
    isLoggedIn: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginHandler (state){
            const loginStatus: string | null= localStorage.getItem("isLoggedIn");
            const tocken = localStorage.getItem("jwt");
            const user_id = localStorage.getItem("user_id")
            const role_id = localStorage.getItem("role_id");
            if(loginStatus == '1' && tocken && user_id){
                state.isLoggedIn = true;    
            }           
        },
        logoutHandler (state){
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('jwt');
            localStorage.removeItem('user_id');
            localStorage.removeItem('role_id');
            state.isLoggedIn = false;
        }
    }
})

export const authSliceActions = authSlice.actions;

export default authSlice;