import React, { Fragment } from "react";

const Main: React.FC<{children: React.ReactNode}> = (props) => {
    return(
        <Fragment>
            {props.children}
        </Fragment>        
    )
};

export default Main;