import React from "react";
const Notification: React.FC<{ content: string, status: string }> = (props) => {

    let icon;
    if (props.status == '1') {
        icon = <svg version="1.0" className="mr-3" xmlns="http://www.w3.org/2000/svg"
            width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="currentColor" stroke="none">
                <path d="M2330 5110 c-482 -43 -950 -230 -1350 -538 -195 -150 -448 -432 -594
                -662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
                -565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
                412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 159 26
                365 79 499 128 120 44 372 167 471 230 321 204 620 503 824 824 63 99 186 351
                230 471 83 228 140 508 152 759 8 154 0 191 -49 247 -102 116 -291 65 -326
                -87 -6 -25 -11 -79 -11 -122 0 -114 -28 -306 -65 -454 -191 -763 -806 -1378
                -1569 -1569 -349 -88 -729 -87 -1076 1 -856 219 -1501 947 -1614 1822 -20 158
                -20 406 0 564 112 874 759 1603 1614 1822 150 38 342 66 458 66 143 1 216 34
                253 114 47 103 7 209 -96 257 -44 20 -144 23 -305 9z"/>
                <path d="M4795 4471 c-16 -4 -41 -13 -55 -21 -14 -7 -549 -536 -1190 -1176
                l-1165 -1162 -290 315 c-537 585 -565 614 -613 635 -91 41 -199 3 -241 -84
                -27 -56 -27 -127 -2 -176 17 -33 305 -350 849 -936 182 -196 220 -226 292
                -226 84 0 68 -15 1372 1287 1348 1346 1287 1280 1276 1378 -13 110 -129 192
                -233 166z"/>
            </g>
        </svg>
    }
    else if (props.status == '0') {
        icon =
            <svg className="mr-3" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="18pt" height="18pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="currentColor" stroke="none">
                    <path d="M2330 5110 c-322 -31 -646 -125 -928 -269 -259 -133 -429 -258 -647
                        -476 -230 -229 -352 -399 -490 -680 -121 -246 -192 -469 -237 -745 -31 -195
                        -31 -565 0 -760 45 -276 116 -499 237 -745 138 -281 260 -451 490 -680 229
                        -230 399 -352 680 -490 246 -121 469 -192 745 -237 195 -31 565 -31 760 0 276
                        45 499 116 745 237 281 138 451 260 680 490 230 229 352 399 490 680 88 179
                        132 296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297
                        -180 476 -138 281 -260 451 -490 680 -229 230 -399 352 -680 490 -246 121
                        -473 193 -740 235 -147 23 -475 34 -615 20z m456 -261 c434 -42 866 -219 1214
                        -497 540 -432 860 -1100 860 -1792 0 -606 -245 -1195 -675 -1625 -430 -430
                        -1019 -675 -1625 -675 -606 0 -1195 245 -1625 675 -430 430 -675 1019 -675
                        1625 0 692 320 1360 860 1792 342 273 781 455 1200 497 125 12 341 13 466 0z"/>
                                    <path d="M1665 3526 c-37 -16 -53 -35 -65 -77 -21 -72 -16 -79 395 -489 l385
                        -385 -385 -385 c-411 -411 -415 -416 -395 -490 17 -63 74 -96 145 -84 37 6 73
                        39 428 392 l387 386 388 -386 c354 -353 390 -386 427 -392 71 -12 128 21 145
                        84 20 74 16 79 -395 490 l-385 385 385 385 c412 411 415 416 395 491 -17 62
                        -74 95 -145 83 -37 -6 -73 -39 -427 -392 l-388 -386 -387 385 c-318 317 -394
                        387 -422 393 -44 8 -51 7 -86 -8z"/>
                </g>
            </svg>
    }

    return (
        <div className={props.status == '1' ? "notification suceess" : 'notification error'}>
            {icon}
            {props.content}
        </div>
    )
}

export default Notification;