import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

type notificationtype = {
    isOPen: boolean,
    status: string,
    content: string,
}

const initialState: notificationtype = {
    isOPen: false,
    status: '',
    content: '',
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        notificationHandler (state, action: PayloadAction<{isOPen: boolean,status: string, content: string}>) {
            state.isOPen = action.payload.isOPen;
            state.status = action.payload.status;
            state.content = action.payload.content;
        },       
    }
});

export default notificationSlice;

export const notificationSliceAction = notificationSlice.actions;