import { configureStore } from "@reduxjs/toolkit";
import navbarCollapseSlice from "./navbarCollapseSlice";
import authSlice from "./authSlice";
import notificationSlice from "./notificationSlice";
import refreshSlice from "./refreshSlice";
import { type } from "os";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        navCollapse: navbarCollapseSlice.reducer,
        notification: notificationSlice.reducer,
        refresh: refreshSlice.reducer,
    }
});

export type RootState = ReturnType<typeof store.getState>

export default store;