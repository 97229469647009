import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const navPortfolio = [
    {
        title: 'Create New',
        image: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14 10.75H10.75V14C10.75 14.41 10.41 14.75 10 14.75C9.59 14.75 9.25 14.41 9.25 14V10.75H6C5.59 10.75 5.25 10.41 5.25 10C5.25 9.59 5.59 9.25 6 9.25H9.25V6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75Z" fill="currentColor"/>
                </svg>`,
        link: '/create-portfolio'
    },
    {
        title: 'Portfolio listing',
        image: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.24 0H3.34C1.15 0 0 1.15 0 3.33V5.23C0 7.41 1.15 8.56 3.33 8.56H5.23C7.41 8.56 8.56 7.41 8.56 5.23V3.33C8.57 1.15 7.42 0 5.24 0ZM16.67 0H14.77C12.59 0 11.44 1.15 11.44 3.33V5.23C11.44 7.41 12.59 8.56 14.77 8.56H16.67C18.85 8.56 20 7.41 20 5.23V3.33C20 1.15 18.85 0 16.67 0ZM16.67 11.43H14.77C12.59 11.43 11.44 12.58 11.44 14.76V16.66C11.44 18.84 12.59 19.99 14.77 19.99H16.67C18.85 19.99 20 18.84 20 16.66V14.76C20 12.58 18.85 11.43 16.67 11.43ZM5.24 11.43H3.34C1.15 11.43 0 12.58 0 14.76V16.66C0 18.85 1.15 20 3.33 20H5.23C7.41 20 8.56 18.85 8.56 16.67V14.77C8.57 12.58 7.42 11.43 5.24 11.43Z" fill="currentColor"/>
                </svg>`,
        link: '/portfolio-listing'
    }
]

const navSettings = [
    {
        title: 'User Management',
        image: `<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.09 10C10.4161 10 11.6879 9.47322 12.6255 8.53553C13.5632 7.59785 14.09 6.32608 14.09 5C14.09 3.67392 13.5632 2.40215 12.6255 1.46447C11.6879 0.526784 10.4161 0 9.09 0C7.76392 0 6.49215 0.526784 5.55447 1.46447C4.61678 2.40215 4.09 3.67392 4.09 5C4.09 6.32608 4.61678 7.59785 5.55447 8.53553C6.49215 9.47322 7.76392 10 9.09 10ZM9.09 12.5C4.08 12.5 0 15.86 0 20C0 20.28 0.22 20.5 0.5 20.5H17.68C17.96 20.5 18.18 20.28 18.18 20C18.18 15.86 14.1 12.5 9.09 12.5Z" fill="currentColor"/>
                </svg>`,
        link: '/user-management'
    },
    {
        title: 'Project Category',
        image: `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6499 22C12.5826 22 12.5042 21.989 12.437 21.967C10.1849 21.3398 7.82075 21.3398 5.56866 21.967C5.1541 22.077 4.71712 21.846 4.60508 21.4389C4.57613 21.342 4.56711 21.2405 4.57856 21.1403C4.59002 21.04 4.62171 20.943 4.67178 20.8549C4.72185 20.7668 4.7893 20.6894 4.87019 20.6272C4.95108 20.565 5.04379 20.5192 5.14289 20.4926C7.67509 19.7884 10.3417 19.7884 12.8739 20.4926C13.2885 20.6136 13.535 21.0317 13.4118 21.4389C13.2997 21.7799 12.986 22 12.6499 22ZM17.0756 4.79463C15.9103 2.484 13.6582 0.778538 11.0476 0.217385C8.31374 -0.376776 5.51264 0.261397 3.37259 1.95586C2.31627 2.78089 1.46404 3.83026 0.879923 5.02513C0.295807 6.22 -0.005011 7.52932 6.31404e-05 8.85473C6.31404e-05 11.7045 1.73675 14.6863 4.35858 16.4138V17.327C4.34738 17.6351 4.33617 18.1082 4.71712 18.4933C5.10928 18.8895 5.69191 18.9335 6.15129 18.9335H11.8992C12.5042 18.9335 12.9636 18.7684 13.2773 18.4603C13.7031 18.0312 13.6919 17.4811 13.6807 17.184V16.4138C17.154 14.1142 19.3389 9.26184 17.0756 4.79463ZM10.9244 10.5822L9.7255 12.6288C9.56864 12.8928 9.28853 13.0469 8.99721 13.0469C8.85099 13.0451 8.70774 13.0061 8.58147 12.9337C8.45519 12.8613 8.35022 12.7579 8.27682 12.6337C8.20342 12.5095 8.16411 12.3688 8.16272 12.2252C8.16134 12.0816 8.19793 11.9401 8.26892 11.8145L9.2213 10.1861H8.28013C7.71991 10.1861 7.26053 9.94403 7.01403 9.53692C6.76753 9.1188 6.78994 8.61267 7.07005 8.12853L8.26892 6.08198C8.50422 5.68587 9.01962 5.55384 9.41177 5.77389C9.81513 6.00496 9.94959 6.5111 9.7255 6.8962L8.77312 8.52464H9.71429C10.2745 8.52464 10.7339 8.76671 10.9804 9.17382C11.2269 9.59193 11.2045 10.1091 10.9244 10.5822Z" fill="currentColor"/>
            </svg>`,
        link: '/project-category'
    },
    {
        title: 'Technology',
        image: `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 14V16.5C17 18.43 15.43 20 13.5 20H3.5C1.57 20 0 18.43 0 16.5V15.85C0 14.28 1.28 13 2.85 13H16C16.55 13 17 13.45 17 14ZM12 0H5C1 0 0 1 0 5V12.58C0.76 11.91 1.76 11.5 2.85 11.5H16C16.55 11.5 17 11.05 17 10.5V5C17 1 16 0 12 0ZM9.5 8.75H4.5C4.09 8.75 3.75 8.41 3.75 8C3.75 7.59 4.09 7.25 4.5 7.25H9.5C9.91 7.25 10.25 7.59 10.25 8C10.25 8.41 9.91 8.75 9.5 8.75ZM12.5 5.25H4.5C4.09 5.25 3.75 4.91 3.75 4.5C3.75 4.09 4.09 3.75 4.5 3.75H12.5C12.91 3.75 13.25 4.09 13.25 4.5C13.25 4.91 12.91 5.25 12.5 5.25Z" fill="currentColor"/>
            </svg>`,
        link: '/technology'
    }
]

const Menu = () => {
    return (
        <Fragment>
            <div className="bk-menu">
                <h2 className='title'>Manage Portfolio</h2>
                <ul>                    
                    {
                        navPortfolio.map((item, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={item.link} className={({ isActive, isPending }) => isPending ? 'pending' : isActive ? 'active' : ''}>
                                        <span dangerouslySetInnerHTML={{ __html: item.image }}></span>
                                        {item.title}
                                    </NavLink>
                                </li>
                            )
                        })
                    }                 
                </ul>
            </div>
            <div className="bk-menu">
                <h2 className='title'>Settings</h2>
                <ul>                   
                    {
                        navSettings.map((item, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={item.link} className={({ isActive, isPending }) => isPending ? 'pending' : isActive ? 'active' : ''}>
                                        <span dangerouslySetInnerHTML={{ __html: item.image }}></span>
                                        {item.title}
                                    </NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </Fragment>
    );
}

export default Menu;