import React, { Fragment, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import PageLoader from './components/UI/PageLoader';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { toggleNavbarCollapse } from './components/store/navbarCollapseSlice';
import { RootState } from './components/store';
import { authSliceActions } from './components/store/authSlice';


const Login = React.lazy(() => import('./components/pages/login/Login'));
const CreatePortfolio = React.lazy(() => import('./components/pages/manage-portfolio/CreatePortfolio'));
const PortfolioListing = React.lazy(() => import('./components/pages/manage-portfolio/PortfolioListing'));
const PortfolioDetail = React.lazy(() => import('./components/pages/manage-portfolio/PortfolioDetail'));
const EditPortfolio = React.lazy(() => import('./components/pages/manage-portfolio/EditPortfolio'));
const UserList = React.lazy(() => import('./components/pages/settings/user-management/UserList'));
const ProjectCategory = React.lazy(() => import('./components/pages/settings/project-cat/ProjectCategory'));
const Technology = React.lazy(() => import('./components/pages/technology/Technology'));
const EditProfile = React.lazy(() => import('./components/pages/edit-profile/EditProfile'));
const ForgotPass = React.lazy(() => import('./components/pages/forgot-pass/ForgotPass'));

function App() {
  let isLogedIn = localStorage.getItem('isLoggedIn') === '1' ? true : false;
  let role_id = localStorage.getItem('role_id');

  const authCheck = useSelector((state: RootState) => state.auth.isLoggedIn);

  useLayoutEffect(() => {
    isLogedIn = localStorage.getItem('isLoggedIn') === '1' ? true : false;
  }, [authCheck]);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleNavbarCollapse());
    dispatch(authSliceActions.loginHandler());
  }, [location])

  let userPermissions;
  let user = localStorage.getItem('user');
  if (user) {
    userPermissions = JSON.parse(user);
  }
  // const createPortfolioPermission = role_id === '1';
  // const portfolioListPermission = role_id === '1';
  // const portfolioEditPermission = role_id === '1';
  // const userListPermission = role_id === '1';
  // const categoriesPermission = role_id === '1';
  // const technologyPermission = role_id === '1';

  const createPortfolioPermission = true;
  const portfolioListPermission = true;
  const portfolioEditPermission = true;
  const userListPermission = true;
  const categoriesPermission = true;
  const technologyPermission = true;

  return (
    <Fragment>
      {isLogedIn &&
        <Layout>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              {createPortfolioPermission &&
                <Fragment>
                  <Route path="/" element={<Navigate to='/create-portfolio' />} />
                  <Route path="/create-portfolio" element={<CreatePortfolio />} />
                  <Route path='/login' element={<Navigate to='/create-portfolio' />} />
                </Fragment>
              }
              {portfolioListPermission &&
                <Fragment>
                  <Route path="/portfolio-listing" element={<PortfolioListing />} />
                  <Route path="/portfolio-listing/:portfolioId" element={<PortfolioDetail />} />
                </Fragment>
              }
              {portfolioEditPermission &&
                <Route path="/portfolio-listing/edit/:portfolioId" element={<EditPortfolio />} />
              }
              {userListPermission &&
                <Route path="/user-management" element={<UserList />} />
              }
              {categoriesPermission &&
                <Route path="/project-category" element={<ProjectCategory />} />
              }
              {technologyPermission &&
                <Route path="/technology" element={<Technology />} />
              }              
              <Route path="/editProfile" element={<EditProfile />} />
            </Routes>
          </Suspense>
        </Layout>
      }
      {!isLogedIn &&
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path='/' element={<Navigate to='/login' />} />
            <Route path='*' element={<Navigate to='/login' />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotpass' element={<ForgotPass />} />
          </Routes>
        </Suspense>
      }
    </Fragment>
  );
}

export default App;
