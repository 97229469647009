import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavbarCollapse } from '../../store/navbarCollapseSlice';
import { Link, useLocation } from 'react-router-dom';
import UserDropdown from './UserDropdown';
import useAxiosPost from '../../hook/useAxiosPost';
import { axiosPostReturnType } from '../../hook/useAxiosPost';
import Loader from '../../UI/Loader';
import { RootState } from '../../store';

const Header = () => {
    const [userName, setUserName] = useState<string>('');
    const [isUserDropdownOPen, setIsUserDropdownOPen] = useState<boolean>(false);

    const profileRefresh = useSelector((state: RootState) => state.refresh.refreshProfileCounter);

    const dispatch = useDispatch();
    

    const { data: userData, isLoaing: userDataLoading, error: userDataError, postRequest: userDataRequest }: axiosPostReturnType = useAxiosPost();

    useEffect(() => {
        userDataRequest('userprofile', '');
    }, [profileRefresh])

    useEffect(() => {
        const userName = userData?.user[0].full_name;
        setUserName(userName);
    }, [userData])

    const navCollapseHandler = () => {
        dispatch(toggleNavbarCollapse());
    }

    document.addEventListener('click', function () {
        setIsUserDropdownOPen(false);
    })

    const stopPropagation = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    };
   

    return (
        <header className="bk-header">
            <div className="container-fluid">
                {userDataLoading && !userDataError && <Loader />}
                {!userDataLoading && !userDataError &&
                    <div className="row align-items-center">
                        <div className="col-md-8 col-9">
                            <div className='d-flex align-items-center'>
                                <button className='bk-mob-tog d-xl-none' onClick={navCollapseHandler}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <div className="title">
                                    <span>Welcome to your  </span>
                                    Portfolio Management
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-3">
                            <div className="d-flex align-items-center justify-content-end">                                
                                <div className='position-relative' onClick={stopPropagation}>
                                    <button className="btn-border" onClick={() => setIsUserDropdownOPen(!isUserDropdownOPen)}>
                                        <span className='mr-lg-2 mr-0 icon'><svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.09 10C10.4161 10 11.6879 9.47322 12.6255 8.53553C13.5632 7.59785 14.09 6.32608 14.09 5C14.09 3.67392 13.5632 2.40215 12.6255 1.46447C11.6879 0.526784 10.4161 0 9.09 0C7.76392 0 6.49215 0.526784 5.55447 1.46447C4.61678 2.40215 4.09 3.67392 4.09 5C4.09 6.32608 4.61678 7.59785 5.55447 8.53553C6.49215 9.47322 7.76392 10 9.09 10ZM9.09 12.5C4.08 12.5 0 15.86 0 20C0 20.28 0.22 20.5 0.5 20.5H17.68C17.96 20.5 18.18 20.28 18.18 20C18.18 15.86 14.1 12.5 9.09 12.5Z" fill="currentColor" />
                                        </svg>
                                        </span>
                                        <span className='d-none d-lg-block'>
                                            {userName}
                                        </span>
                                    </button>
                                    {isUserDropdownOPen && <UserDropdown />}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {userDataError && <div className='error'>{userDataError}</div>}
            </div>
        </header>
    );
};

export default Header;