import { Fragment } from "react";

const Loader = () => {
    return (
        <Fragment>
            <div className="ssc-line mb-4"></div>
            <div className="ssc-line mb-4"></div>
            <div className="ssc-line mb-4"></div>
        </Fragment>
    );
};

export default Loader;