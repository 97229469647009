import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Main from "./Main";
import { RootState } from "../store";
import { toggleNavbarCollapse } from "../store/navbarCollapseSlice";
import Notification from "../UI/Notification";

const Layout: React.FC<{ children: React.ReactNode }> = (props) => {
    const isCollapse = useSelector((state: RootState) => state.navCollapse.isCollpase);

    const isnotificationOpen = useSelector((state: RootState) => state.notification.isOPen);
    const notificationContent = useSelector((state: RootState) => state.notification.content);
    const notificationStatus = useSelector((state: RootState) => state.notification.status);

    const dispatch = useDispatch();
    const navbarCollpseHandler = () => {
        dispatch(toggleNavbarCollapse())
    }

    return (
        <Fragment>
            <div className="bk-wrapper">
                <div className={isCollapse ? "nav-mask active d-xl-none" : "nav-mask d-xl-none"} onClick={navbarCollpseHandler}></div>
                <Sidebar />
                <div className={isCollapse ? "bk-page active" : "bk-page"}>                    
                    <Header />
                    <Main>
                        {props.children}
                    </Main>
                </div>
            </div>
            {isnotificationOpen && <Notification content = {notificationContent} status = {notificationStatus}/>}
        </Fragment>
    )
};

export default Layout;