import { createSlice } from "@reduxjs/toolkit";

type refreshType = {
    refreshProfileCounter: number,
    refresCatCounter: number,
    refreshClientCounter: number,
    refreshTechCounter: number,
    refreshUserCounter: number,
    refreshProjectCounter: number,    
}

const initialState: refreshType = {
    refreshProfileCounter: 0,
    refresCatCounter: 0,
    refreshClientCounter: 0,
    refreshTechCounter: 0,
    refreshUserCounter: 0,
    refreshProjectCounter: 0,
}

const refreshSlice = createSlice({
    name: 'refreshAccount',
    initialState: initialState,
    reducers: {
        refreshProfileHandler (state) {
            state.refreshProfileCounter = state.refreshProfileCounter + 1;
        },
        refreshCatHandler (state) {
            state.refresCatCounter = state.refresCatCounter + 1;
        },
        refreshClientHandler (state) {
            state.refreshClientCounter = state.refreshClientCounter + 1;
        },
        refreshTechHandler (state) {
            state.refreshTechCounter = state.refreshTechCounter + 1;
        },
        refreshUserHandler (state) {
            state.refreshUserCounter = state.refreshUserCounter + 1;
        },
        refreshProjectHandler (state) {
            state.refreshProjectCounter = state.refreshProjectCounter + 1;
        }
    }
})

export default refreshSlice;

export const refreshSliceAction = refreshSlice.actions;